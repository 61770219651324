<template>
  <div class="container">
    <div class="particle-container">
      <div class="particle a">
      </div>
      <div class="particle b">
      </div>
    </div>
    <div class="text-container">
      <h1>we make it easy</h1>
      <h1>we make it easy</h1>
      <h1>we make it easy</h1>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ServicesTitle',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-container {
  position: relative;
  display: flex;
  gap: 20px;
  overflow: hidden;
  height: 100%;
}
@keyframes floating1 {
  0% {
    z-index: 1;
    top: calc(0 + 100px);
  }

  50% {
    top: calc(100% - 50px);
  }

  100% {
    z-index: 0;
    top: calc(0 + 100px);
  }
}

@keyframes floating2 {
  0% {
    z-index: 1;
    top: 0;
  }

  50% {
    top: calc(100% - 34px);
  }

  100% {
    z-index: 0;
    top: 0;
  }
}

.particle-container {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.particle {
  border-radius: 100%;
  backdrop-filter: blur(5px);
  filter: blur(2px);
  /*   animation: floating 3s ease-in-out infinite; */
  /* z-index: 1; */
}

.a {
  background: linear-gradient(90deg, #0e7cb328 0%, #1184CD 100%);
  position: absolute;
  transform: rotate(100deg);
  right: 20%;
  top: -40px;
  width: 100px;
  height: 100px;
  animation: floating1 3s ease-in-out infinite;
}

.b {
  background: linear-gradient(90deg, #0e7cb32c 0%, #86F7FD 100%);
  position: absolute;
  transform: rotate(10deg);
  left: 10%;
  top: 30px;
  width: 34px;
  height: 34px;
  animation: floating2 2s ease-in-out infinite;

}

.container {
  position: relative;
  margin-top: 40px;
  pointer-events: none;
  user-select: none;
  width: 100%;
}

h1 {
  background-image: url("@/assets/backgroundWeMake.webp");
  background-size: 135%;
  background-position: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  font-size: 20vh;
  text-wrap: nowrap;
  text-transform: uppercase;
  margin: 0px;
  animation: slide 10s linear infinite;
}

p {
  font-weight: lighter;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
</style>
